<template>
  <AppBase v-if="authenticated" :items="items" />
  <Authentication v-else />
</template>

<script>
import { LoginBff as Authentication } from "ngt-frontend-core";
import { AppBase, memory, usersBff } from "ngt-frontend-core";
import dayjs from "dayjs";
const { apiOpsBff } = require("ngt-frontend-core");

const acceptedTags = {
  admin: "role:abac-admin",
  user: "role:abac-admin"
};

export default {
  name: "App",
  components: {
    AppBase,
    Authentication
  },
  data: () => {
    return {
      existingTags: [],
      items: [],
      timers: {},
      timerId: undefined
    };
  },
  created() {
    if (this.authenticated) {
      // set global timer handling
      this.$EventBus.$on("timerAttach", (callbackEvent, interval = 0) => {
        this.timers[callbackEvent] = this.timers[callbackEvent] || {};
        this.timers[callbackEvent].interval = interval;
        this.timers[callbackEvent].called = new Date().getTime();
      });
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    }

    this.$EventBus.$on("buildMenuItems", () => {
      this.buildMenuItems();
      // this.$store.commit("page", this.$route.meta.title());
    });
    this.init();
  },
  beforeDestroy() {
    window.cancelAnimationFrame(this.timerId);
    this.timerId = undefined;
    this.$EventBus.$off();
  },
  computed: {
    authenticated() {
      // this.buildMenuItems();
      return this.$store.state.user;
    }
  },
  watch: {
    async $route(to, from) {
      // re-update local settings to match with user default locale
      const locale = this.getDefaultLocale();
      const short_locale = locale.substring(0, 2);

      this.$i18n.locale = short_locale;
      dayjs.locale(short_locale);

      const timezone = this.getDefaultTimeZone();
      dayjs.tz.setDefault(timezone);

      // refresh available menu points
      // this.buildMenuItems();
      await apiOpsBff.setI18nLanguage(locale);
      this.$EventBus.$emit("buildMenuItems");

      if (typeof to.meta.title === "function") {
        // this.$store.commit("page", to.meta.title());
      }
    }
  },
  methods: {
    async init() {
      const browserLanguage = usersBff.getBrowserLanguage();
      const storedLocale = await memory.getItemAsync("locale");
      const locale = storedLocale || browserLanguage || "en";

      await apiOpsBff.setI18nLanguage(locale);
      this.$EventBus.$emit("buildMenuItems");

      dayjs.locale(
        locale && locale.length === 2
          ? `${locale}_${locale.toUpperCase()}`
          : locale
      );
    },
    executeTimedEvents() {
      const currTime = new Date().getTime();
      for (const item in this.timers) {
        if (currTime - this.timers[item].called > this.timers[item].interval) {
          delete this.timers[item];
          this.$EventBus.$emit(item);
        }
      }
      this.timerId = window.requestAnimationFrame(this.executeTimedEvents);
    },
    buildMenuItems() {
      const noLicense = [
        {
          title: this.$t("no_license"),
          action: "NoLicense"
        }
      ];
      const standard = [];
      const admin = [
        // {
        //   title: this.$t("select_resource"),
        //   action: "List of Resources",
        //   icon: "mdi-eyedropper-variant"
        // },
        {
          title: this.$t("resource_rules"),
          action: "Home",
          icon: "mdi-police-badge"
        },
        {
          title: this.$t("collection_manager"),
          action: "Collection Manager",
          icon: "mdi-bookmark-box-multiple-outline"
        },
        this.isIDPSelected && {
          title: this.$t("manage_identities"),
          action: "Identities",
          icon: "mdi-account-key-outline"
        },
        {
          title: this.$t("resource-servers"),
          action: "Resource Servers",
          icon: "mdi-server"
        },
        {
          title: this.$t("clients"),
          action: "Clients",
          icon: "mdi-monitor-cellphone"
        },
        {
          title: this.$t("scopes"),
          action: "Scopes",
          icon: "mdi-web"
        },
        {
          title: this.$t("data-providers"),
          action: "Data Providers",
          icon: "mdi-check-decagram-outline"
        },
        {
          title: this.$t("data-stores"),
          action: "Data Stores",
          icon: "mdi-database-outline"
        },
        {
          title: this.$t("licenseTemplates"),
          action: "LicenseTemplates",
          icon: "mdi-file-document-outline"
        },
        {
          title: this.$t("licenses"),
          action: "Licenses",
          icon: "mdi-license"
        },
        {
          title: this.$t("customers"),
          action: "Customers",
          icon: "mdi-domain"
        },
        {
          title: this.$t("groups"),
          action: "Groups",
          icon: "mdi-account-group-outline"
        },
        {
          title: this.$t("eventListener.menu"),
          action: "EventListener",
          icon: "mdi-stethoscope"
        },
        {
          title: this.$t("BFFDebugger"),
          action: "BFFDebugger",
          icon: "mdi-bug-outline"
        }
      ];
      if (this.authenticated && this.authenticated.ownedBy) {
        // TODO: handling rights and menu access in the future
        this.items = standard.concat(admin);
      } else {
        this.items = noLicense;
      }
    },
    isIDPSelected() {
      if (this.$store.state.idp_api_url === this.$store.state.selected_resource)
        return true;
      return false;
    }
  }
};
</script>

<style lang="scss">
$white: #ffffff;
$black: #000000;
$lightgreen: #9ad2ce;
$accent: #8bd1cc;
$darkgreen: #6aa19d;
$gray1: #f0f3f5;
$gray2: #5d6168;
$gray3: #696a6f;
$gray4: #e5e5e5;
$red: #d26464;

$mBlue: #49cc90;
$mGreen: #61affe;
$mRed: #f93e3e;
$mLightGreen: #50e3c2;
$mOrange: #fca130;

main {
  overflow-y: scroll;
  overflow-x: hidden;
  padding-bottom: 20px !important;
}
.main-view {
  position: relative;
  height: auto;
}
.v-menu__content {
  background-color: $white;
}

/* drawer menu bottom logos */
.v-navigation-drawer__append {
  .v-list {
    .v-list-item {
      .container {
        border-top: 1px solid rgba(255, 255, 255, 0.12);
        padding-left: 0 !important;
        padding-right: 0 !important;

        .row {
          flex-direction: column;
          .col {
            max-width: 90%;
            padding-bottom: 0 !important;
          }
          .col:nth-child(1) {
            .v-image__image {
              background-image: url("assets/text-logo.png") !important;
              background-size: contain;
            }
          }
          .col:nth-child(2) {
            .v-responsive__sizer {
              padding-bottom: 20px !important;
            }
            .v-image__image {
              background-size: contain;
              height: 18px;
            }
          }
        }
      }
    }
  }
}

.scrollLimit {
  max-height: 300px;
  overflow-y: scroll;
}
.dataSelector {
  // max-width: 300px;
  width: 96%;
  margin: 0 auto;

  .topTitle {
    border-bottom: 1px solid #afafaf;
  }

  .v-list-item__subtitle {
    max-width: 274px;
  }

  .bottom-search-for-selector {
    border-top: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }

  .top-search-for-selector {
    border-bottom: 1px solid #afafaf;

    label,
    .v-icon {
      opacity: 0.5;
    }
  }
}

.v-application {
  .pa-0 {
    padding: 0 !important;
  }
}
.pa-0 {
  padding: 0 !important;
}
.more-space {
  white-space: normal !important;
  max-height: 116px !important;
}
.dataSelector {
  .v-list-item {
    .v-list-item__title {
      color: rgba(0, 0, 0, 1) !important;
    }
  }
}
.fa-square::before {
  color: rgba(0, 0, 0, 0.6) !important;
}
.fa-check-square::before {
  color: #0090d4 !important;
}
tr.v-data-table__expanded__row {
  color: white;
  background: #1976d2 !important;
}
tr.v-data-table__expanded__content {
  background: #eeeeee !important;
}
.close-button {
  color: white !important;
  background-color: #696a6f !important;
}

.link-like {
  cursor: pointer;
  display: inline-block;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
  }
}
</style>
