import Vue from "vue";
import dayjs from "dayjs";
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

Vue.filter("empty", function(value) {
  if (value === null || typeof value === "undefined" || value === "") {
    return "-";
  }
  return value;
});

Vue.filter("getDisplayDate", function(date, format) {
  if (!date) {
    return "";
  }

  if (typeof date === "string" && date.length === 13) {
    date = Number(date);
  }

  if (format === "date") {
    return dayjs(date).format("YYYY.MM.DD");
  }
  if (format === "estimate") {
    return dayjs(date).fromNow();
  }
  return dayjs(date).format("YYYY.MM.DD HH:mm");
});

Vue.filter("cronObject", function(item) {
  return {
    day: item.day,
    dayType: item.dayType,
    month: item.month,
    hour: item.hour,
    minute: item.minute
  };
});

Vue.filter("shortenText", function(text, length = 280) {
  if (!text) {
    return "";
  }
  if (text.length > length) {
    return `${text.substring(0, length - 1)}...`;
  }
  return text;
});
