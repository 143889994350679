function treeForEach(root, fn) {
  function processNodeReq(node) {
    fn(node);
    for (const child of node.children) {
      processNodeReq(child);
    }
  }
  if (Array.isArray(root)) {
    for (const child of root) {
      processNodeReq(child);
    }
    return;
  }
  processNodeReq(root);
}

function treeFind(root, fn) {
  try {
    treeForEach(root, node => {
      if (fn(node) === true) {
        const err = new Error("FOUND");
        err.node = node;
        throw err;
      }
    });
  } catch (err) {
    if (err.message === "FOUND") return err.node;
  }
  return null;
}

function isEqualObj(obj1, obj2) {
  return JSON.stringify(obj1) == JSON.stringify(obj2);
}

module.exports = { treeForEach, treeFind, isEqualObj };
