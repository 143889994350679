import getLocale from "./getLocale";

export const formatDate = value => {
  if (!value) {
    return "-";
  }
  const dateTime = new Date(value);
  return dateTime.toLocaleDateString(getLocale());
};

export const formatTime = value => {
  if (!value) {
    return "-";
  }
  const time = parseInt(value, 10);
  const h = Math.floor(time / 3600);
  const m = Math.floor((time % 3600) / 60);
  return `${String(h).padStart(2, 0)}:${String(m).padStart(2, 0)}`;
};

export const formatDateTime = value => {
  if (!value) {
    return "-";
  }
  const dateTime = new Date(value);
  return dateTime.toLocaleString(getLocale());
};

export const formatDateTimeFromEPOC = value => {
  if (!value) {
    return "-";
  }
  const dateTime = new Date(value * 1);
  return dateTime.toLocaleString(getLocale());
};
