import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "@/locales/i18n";
import { LoginBff } from "ngt-frontend-core";
import { NoLicense } from "ngt-frontend-core";

Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    /* common routes */
    {
      path: "/",
      name: "Home",
      component: () => import("@/views/ResourcePolicies"),
      meta: {
        title: () => i18n.t("resource_rules")
      }
    },
    {
      path: "/resources",
      name: "List of Resources",
      component: () => import("@/views/Resources"),
      meta: {
        title: () => i18n.t("list_of_resources")
      }
    },
    {
      path: "/identities",
      name: "Identities",
      component: () => import("@/views/Identities"),
      meta: {
        title: () => i18n.t("identities")
      }
    },
    {
      path: "/identities/:identityId",
      name: "Identity",
      component: () => import("@/views/Identity"),
      meta: {
        title: () => i18n.t("identity")
      }
    },
    {
      path: "/identities/:identityId/profiles",
      name: "Identity Profiles",
      component: () => import("@/views/IdentityProfiles"),
      meta: {
        title: () => i18n.t("profiles")
      }
    },
    {
      path: "/identities/:identityId/attrs",
      name: "Identity Attributes",
      component: () => import("@/views/EntityAttributes"),
      meta: {
        title: () => i18n.t("attributes")
      }
    },
    {
      path: "/identities/:identityId/tags",
      name: "Identity Tags",
      component: () => import("@/views/EntityTags"),
      meta: {
        title: () => i18n.t("tags")
      }
    },
    {
      path: "/identities/:identityId/tokens",
      name: "Identity Tokens",
      component: () => import("@/views/IdentityTokens"),
      meta: {
        title: () => i18n.t("tokens")
      }
    },
    {
      path: "/collection/",
      name: "Collection Manager",
      component: () => import("@/views/CollectionManager"),
      meta: {
        title: () => i18n.t("collection_manager")
      }
    },
    {
      path: "/resource-servers/",
      name: "Resource Servers",
      component: () => import("@/views/ResourceServers"),
      meta: {
        title: () => i18n.t("resource-servers")
      }
    },
    {
      path: "/resource-servers/:resourceServerId",
      name: "Resource Server",
      component: () => import("@/views/ResourceServer"),
      meta: {
        title: () => i18n.t("resource-server")
      }
    },
    {
      path: "/resource-servers/:resourceServerId/attrs",
      name: "Resource Server Attributes",
      component: () => import("@/views/EntityAttributes"),
      meta: {
        title: () => i18n.t("attributes")
      }
    },
    {
      path: "/resource-servers/:resourceServerId/tags",
      name: "Resource Server Tags",
      component: () => import("@/views/EntityTags"),
      meta: {
        title: () => i18n.t("tags")
      }
    },
    {
      path: "/clients/",
      name: "Clients",
      component: () => import("@/views/Clients"),
      meta: {
        title: () => i18n.t("clients")
      }
    },
    {
      path: "/clients/:clientId",
      name: "Client",
      component: () => import("@/views/Client"),
      meta: {
        title: () => i18n.t("client")
      }
    },
    {
      path: "/clients/:clientId/attrs",
      name: "Client Attributes",
      component: () => import("@/views/EntityAttributes"),
      meta: {
        title: () => i18n.t("attributes")
      }
    },
    {
      path: "/clients/:clientId/tags",
      name: "Client Tags",
      component: () => import("@/views/EntityTags"),
      meta: {
        title: () => i18n.t("tags")
      }
    },
    {
      path: "/scopes/",
      name: "Scopes",
      component: () => import("@/views/Scopes"),
      meta: {
        title: () => i18n.t("scopes")
      }
    },
    {
      path: "/scopes/:scopeId",
      name: "Scope",
      component: () => import("@/views/Scope"),
      meta: {
        title: () => i18n.t("scope")
      }
    },
    {
      path: "/data-providers/",
      name: "Data Providers",
      component: () => import("@/views/DataProviders"),
      meta: {
        title: () => i18n.t("data-providers")
      }
    },
    {
      path: "/data-providers/:dataProviderId",
      name: "Data Provider",
      component: () => import("@/views/DataProvider"),
      meta: {
        title: () => i18n.t("data-provider")
      }
    },
    {
      path: "/data-providers/:dataProviderId/attrs",
      name: "Data Provider Attributes",
      component: () => import("@/views/EntityAttributes"),
      meta: {
        title: () => i18n.t("attributes")
      }
    },
    {
      path: "/data-providers/:dataProviderId/tags",
      name: "Data Provider Tags",
      component: () => import("@/views/EntityTags"),
      meta: {
        title: () => i18n.t("tags")
      }
    },
    {
      path: "/data-stores/",
      name: "Data Stores",
      component: () => import("@/views/DataStores"),
      meta: {
        title: () => i18n.t("data-stores")
      }
    },
    {
      path: "/data-stores/:dataStoreId",
      name: "Data Stores",
      component: () => import("@/views/DataStore"),
      meta: {
        title: () => i18n.t("data-store")
      }
    },
    {
      path: "/data-stores/:dataStoreId/attrs",
      name: "Data Stores Attributes",
      component: () => import("@/views/EntityAttributes"),
      meta: {
        title: () => i18n.t("attributes")
      }
    },
    {
      path: "/data-stores/:dataStoreId/tags",
      name: "Data Stores Tags",
      component: () => import("@/views/EntityTags"),
      meta: {
        title: () => i18n.t("tags")
      }
    },
    {
      path: "/license-templates/",
      name: "LicenseTemplates",
      component: () => import("@/views/LicenseTemplates"),
      meta: {
        title: () => i18n.t("licenseTemplates")
      }
    },
    {
      path: "/license-templates/:licenseTemplateId",
      name: "LicenseTemplate",
      component: () => import("@/views/LicenseTemplate"),
      meta: {
        title: () => i18n.t("licenseTemplate")
      }
    },
    {
      path: "/licenses/",
      name: "Licenses",
      component: () => import("@/views/Licenses"),
      meta: {
        title: () => i18n.t("licenses")
      }
    },
    {
      path: "/licenses/:customerId",
      name: "License",
      component: () => import("@/views/License"),
      meta: {
        title: () => i18n.t("license")
      }
    },
    {
      path: "/customers/",
      name: "Customers",
      component: () => import("@/views/Customers"),
      meta: {
        title: () => i18n.t("customers")
      }
    },
    {
      path: "/customers/:customerId",
      name: "Customer",
      component: () => import("@/views/Customer"),
      meta: {
        title: () => i18n.t("customer")
      }
    },
    {
      path: "/groups/",
      name: "Groups",
      component: () => import("@/views/Groups"),
      meta: {
        title: () => i18n.t("groups")
      }
    },
    {
      path: "/groups/",
      name: "Groups",
      component: () => import("@/views/Groups"),
      meta: {
        title: () => i18n.t("groups")
      }
    },
    {
      path: "/event-listener/",
      name: "EventListener",
      component: () => import("@/views/EventListener"),
      meta: {
        title: () => i18n.t("eventListener.menu")
      }
    },
    {
      path: "/bff-debugger/",
      name: "BFFDebugger",
      component: () => import("@/views/BFFDebugger"),
      meta: {
        title: () => i18n.t("BFFDebugger")
      }
    },
    /*service routes */
    {
      path: "/authentication",
      name: "Login Page",
      component: LoginBff,
      meta: {
        title: () => i18n.t("login")
      }
    },
    {
      path: "/settings",
      name: "Settings",
      component: () => import("@/views/Settings"),
      meta: {
        title: () => i18n.t("title_settings")
      }
    },
    {
      path: "/noLicense",
      name: "NoLicense",
      component: NoLicense,
      meta: {
        title: () => i18n.t("no_license")
      }
    }
  ]
});

// Helper function to prevent duplicate navigation error
router.push_safe = params => {
  let from = router.currentRoute.fullPath;
  let to = router.resolve(params).route.fullPath;
  if (from === to) return;

  router.push(params);
};

export default router;
