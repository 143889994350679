export function createTree(strings) {
  const rootNode = {
    id: "",
    children: []
  };

  for (const string of strings) {
    const parts = string.split(":");
    let currentNode = rootNode;

    for (const part of parts) {
      let childNode = currentNode.children.find(node => node.name === part);

      if (!childNode) {
        childNode = {
          id: currentNode.id ? `${currentNode.id}:${part}` : part,
          name: part,
          isSelected: false,
          isDisabled: false,
          numOfChildrens: null,
          numOfSelectedChildrens: null,
          isLeaf: null,
          children: []
        };
        currentNode.children.push(childNode);
        currentNode.children.sort((a, b) => a.id.localeCompare(b.id)); // Sort children alphabetically
      }

      currentNode = childNode;
    }
  }

  return rootNode;
}

function calculateNumOfChildrensAndSelected_req(node) {
  // Base case: If the node has no children, set numOfChildrens to 0 and return.
  if (!node.children || node.children.length === 0) {
    node.numOfChildrens = 0;
    node.numOfSelectedChildrens = node.isSelected ? 1 : 0;
    node.isLeaf = true;
    return 1; // Return 1 to indicate that this node has been processed.
  }

  // Recursive case: Initialize the counts for this node to 0.
  let numOfChildrens = 0;
  let numOfSelectedChildrens = node.isSelected ? 1 : 0;

  // Loop through the children of the current node and recursively calculate counts.
  for (const child of node.children) {
    const childCount = calculateNumOfChildrensAndSelected_req(child);
    numOfChildrens += childCount;
    numOfSelectedChildrens += child.numOfSelectedChildrens;
  }

  // Update the numOfChildrens and numOfSelectedChildrens properties for the current node.
  node.numOfChildrens = numOfChildrens;
  node.numOfSelectedChildrens = numOfSelectedChildrens;
  node.isLeaf = false;

  // Return the total count of children for this node.
  return numOfChildrens + 1;
}

export function calculateNumOfChildrensAndSelected(items) {
  // Call the function for each top-level item.
  for (const item of items) {
    calculateNumOfChildrensAndSelected_req(item);
  }
}

function collectIdsWithSelectedChildren_req(node, result = []) {
  if (node.numOfSelectedChildrens > 0) {
    result.push(node.id);
  }

  if (node.children && node.children.length > 0) {
    for (const child of node.children) {
      collectIdsWithSelectedChildren_req(child, result);
    }
  }

  return result;
}

export function collectIdsWithSelectedChildren(items) {
  const collectedIds = [];
  for (const item of items) {
    collectIdsWithSelectedChildren_req(item, collectedIds);
  }
  return collectedIds;
}
