import Vue from "vue";
import { i18n } from "@/locales/i18n";
const { callAPI, callBffAPI } = require("ngt-frontend-core").apiOpsBff;
import dayjs from "dayjs";
import locales from "@/assets/locales.json";
import countries from "i18n-iso-countries";
import languages from "@cospired/i18n-iso-languages";

// var localizedFormat = require("dayjs/plugin/localizedFormat");
// dayjs.extend(localizedFormat);

Vue.mixin({
  methods: {
    errorSnackbar(error) {
      this.$store.state.snackbar = {
        text: error,
        color: "error",
        timeout: 10000,
        open: true
      };
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    cloneObj(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    distinct(array) {
      return [...new Set(array)];
    },

    // It takes an array of normal objects
    arrToHash(arr, key) {
      key = key || "_id";
      if (arr.length == 0) return {};
      if (typeof arr[0] !== "object") return {};
      const result = {};
      arr.forEach(p => (result[p[key]] = this.omit(p, key)));
      return result;
    },

    // Creates an array of normal objects
    hashToArr(hash, key) {
      key = key || "_id";
      const hashKeys = Object.keys(hash);
      if (hashKeys.length == 0) return [];
      if (typeof hash[hashKeys[0]] !== "object") return [];
      const result = hashKeys.map(p => ({ [key]: p, ...hash[p] }));
      return result;
    },

    // Creates an array of dropdown options (eg.: [{ value: first, text: "foo" }])
    hashToArrOptions(hash) {
      const hashKeys = Object.keys(hash);
      if (hashKeys.length == 0) return [];
      if (typeof hash[hashKeys[0]] === "object") return [];
      const result = hashKeys.map(p => ({ ["value"]: p, text: hash[p] }));
      return result;
    },

    arrSortAlphabetically(arr, { direction, key } = {}) {
      if (!Array.isArray(arr)) return null;
      if (arr.length == 0) return [];

      direction = direction || "asc";
      key = key || "name";

      const isObj = typeof arr[0] === "object";
      let result = isObj
        ? arr.sort((a, b) => a[key].localeCompare(b[key]))
        : arr.sort((a, b) => a.localeCompare(b));

      if (direction == "desc") result.reverse();
      return result;
    },

    treeForEach(root, fn) {
      function processNodeReq(node) {
        fn(node);
        for (const child of node.children) {
          processNodeReq(child);
        }
      }
      if (Array.isArray(root)) {
        for (const child of root) {
          processNodeReq(child);
        }
        return;
      }
      processNodeReq(root);
    },

    treeFind(root, fn) {
      try {
        this.treeForEach(root, node => {
          if (fn(node) === true) {
            const err = new Error("FOUND");
            err.node = node;
            throw err;
          }
        });
      } catch (err) {
        if (err.message === "FOUND") return err.node;
      }
      return null;
    },

    async checkJob(jobId) {
      return new Promise(resolve => {
        callAPI({
          url: `${this.$store.state.report_manager_api}/jobs/${jobId}`,
          method: "GET"
        })
          .then(response => {
            resolve(response.data.status.split(".").pop());
          })
          .catch(error => {
            this.$store.state.snackbar = {
              text: error,
              color: "error",
              timeout: 10000,
              open: true
            };
          });
      });
    },

    /* helpers */
    async askForData(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async askForDataBff(url) {
      try {
        // console.log("callAPI url: ", url)
        const result = await callBffAPI({ url, method: "GET", cache: true });
        // console.log("callAPI result", result)
        return result.data;
      } catch (err) {
        // console.log("callAPI error", err)
        return null;
      }
    },

    async getIdentities() {
      const result = await callBffAPI({
        url: "/identities",
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getIdentityName(identityId) {
      const url = `/identities?identityId=${identityId}&fields=name`;
      const method = "GET";
      const result = await callBffAPI({ url, method });
      if (!result.data?.length) return null;
      return result.data[0].name;
    },

    async getIdentity(identityId) {
      const url = `/identities?identityId=${identityId}&r8sFields=ownedBy.name,createdBy.name,updatedBy.name,mfUser.username`;
      const method = "GET";
      const result = await callBffAPI({ url, method });
      if (!result.data?.length) return null;
      return result.data[0];
    },

    async getIdentityProfiles(identityId) {
      const url = `${this.$store.state.idp_api_url}/identities/${identityId}/profiles`;
      const method = "GET";
      const result = await callAPI({ url, method });
      if (result == null) return null;
      return result.data;
    },

    async getRole(url, additionalParams) {
      additionalParams = additionalParams || "";
      const result = await callAPI({
        url: `${
          this.$store.state.idp_api_url
        }/permission-templates?filter=_uri:in:[${encodeURIComponent(
          url
        )}]${additionalParams}`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getScope(url, additionalParams) {
      additionalParams = additionalParams || "";
      const result = await callAPI({
        url: `${
          this.$store.state.idp_api_url
        }/scopes?filter=_uri:in:[${encodeURIComponent(
          url
        )}]${additionalParams}`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getClient(url, additionalParams) {
      const result = await callAPI({
        url: `${
          this.$store.state.idp_api_url
        }/clients?filter=_uri:in:[${encodeURIComponent(url)}]${
          additionalParams ? additionalParams : ""
        }`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getResourceServer(url, additionalParams) {
      const result = await callAPI({
        url: `${
          this.$store.state.idp_api_url
        }/resource-servers?filter=_uri:in:[${encodeURIComponent(url)}]${
          additionalParams ? additionalParams : ""
        }`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    getStatusColor(status) {
      let statusTail = "";
      status.includes(".")
        ? (statusTail = status.split(".").pop())
        : (statusTail = status);
      switch (statusTail) {
        case "success":
          return "green";
        case "failed":
          return "red";
        case "purged":
        case "deleted":
        case "inactive":
          return "grey";
        case "active":
        case "processed":
          return "blue";
        case "pending":
          return "orange";
        default:
          return "yellow";
      }
    },

    async getUserName(url) {
      if (!url) return "";
      // url += `/attrs/name`;
      const data = await this.askForData(url);
      if (data == null) return i18n.t("not-available");
      return data.name;
    },

    async getAssetCode(ownedBy, url) {
      if (!url) return "";
      try {
        const _ownedBy = `&ownedBy=${encodeURIComponent(ownedBy)}`;
        const assetId = url.substring(url.lastIndexOf("/") + 1);
        const _url = `/assets?filter=assetId:in:[${assetId}]${_ownedBy}`;
        const result = await callBffAPI({
          url: _url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (!result.data) return "";
        if (result.data.length != 1) return "";
        return result.data[0].assetCode;
      } catch {
        return "";
      }
    },

    async getDriverName(ownedBy, url) {
      if (!url) return "";
      try {
        const _ownedBy = `&ownedBy=${encodeURIComponent(ownedBy)}`;
        const driverId = url.substring(url.lastIndexOf("/") + 1);
        const _url = `/drivers?filter=driverId:in:[${driverId}]${_ownedBy}`;
        const result = await callBffAPI({
          url: _url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (!result.data) return "";
        if (result.data.length != 1) return "";
        return result.data[0].driverName;
      } catch {
        return "";
      }
    },

    async getGroupNames(urls) {
      try {
        // console.log(urls)

        const groupIds = urls.map(p => p.substring(p.lastIndexOf("/") + 1));
        // console.log("groupIds", groupIds)
        const _url = `/groups?filter=groupId:in:[${groupIds.join(
          ","
        )}]&fields=name`;
        // console.log("_url", _url)

        // _url = _url.replace("https://ngt-group-res.gsgroup.io", "")
        const result = await callBffAPI({
          url: _url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (!result.data) return "";
        return result.data.map(p => p.name).join(",");
      } catch {
        return "";
      }
    },

    async getGroupToken(url) {
      try {
        // console.log(urls)

        const groupId = url.substring(url.lastIndexOf("/") + 1);
        // console.log("groupIds", groupIds)
        const _url = `/groups?filter=groupId:eq:${groupId}&fields=groupToken`;
        // console.log("_url", _url)

        // _url = _url.replace("https://ngt-group-res.gsgroup.io", "")
        const result = await callBffAPI({
          url: _url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (!result.data) return "";
        return result.data.groupToken;
      } catch {
        return "";
      }
    },

    async getOwnerToken(url) {
      try {
        // console.log(urls)

        const customerId = url.substring(url.lastIndexOf("/") + 1);
        // console.log("groupIds", groupIds)
        const _url = `/customers?filter=customerId:eq:${customerId}&fields=ownerToken`;
        // console.log("_url", _url)

        // _url = _url.replace("https://ngt-group-res.gsgroup.io", "")
        const result = await callBffAPI({
          url: _url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (result.data.length != 1) return "";
        return result.data[0].ownerToken;
      } catch {
        return "";
      }
    },

    async getGroupTokenOwnedByCustomer(uri) {
      try {
        const url = `/groups?ownedBy=${uri}`;
        const result = await callBffAPI({
          url,
          method: "GET",
          cache: true
        });
        if (result.status !== 200) return "";
        if (result.data.length != 1) return "";
        return result.data[0].groupToken;
      } catch {
        return "";
      }
    },

    getDefaultLocale() {
      let locale = localStorage.getItem("locale");
      if (locale == "null") locale = null;
      if (!locale && this.$store && this.$store.state.user) {
        locale = this.$store.state.user.locale;
      }
      if (!locale) {
        locale = "en_US"; //fallback to english
      }
      return locale;
    },

    getDefaultLocaleShort() {
      const defaultLocale = this.getDefaultLocale();
      return defaultLocale.substring(0, 2);
    },

    getAllLocales() {
      let defaultLocale = this.getDefaultLocaleShort();
      if (defaultLocale == "nb") defaultLocale = "no";
      const result = locales.map(p => ({
        value: p,
        localeShort: p.substring(0, 2),
        text: `${languages.getName(
          p.substring(0, 2),
          defaultLocale
        )} (${this.getCountryName(p.substring(3))})`
      }));
      return result;
    },

    getLocaleName(locale) {
      let defaultLocale = this.getDefaultLocaleShort();
      if (defaultLocale == "nb") defaultLocale = "no";
      return `${languages.getName(
        locale.substring(0, 2),
        defaultLocale
      )} (${this.getCountryName(locale.substring(3))})`;
    },

    getAllCountries() {
      const defaultLocale = this.getDefaultLocaleShort();
      const _countries = countries.getNames(defaultLocale, {
        select: "official"
      });
      const result = this.hashToArrOptions(_countries);
      return result;
    },

    getCountryName(countyCode) {
      const defaultLocale = this.getDefaultLocaleShort();
      return countries.getName(countyCode, defaultLocale, {
        select: "official"
      });
    },

    getDefaultTimeZone() {
      let timezone = localStorage.getItem("timezone");
      if (timezone == "null") timezone = null;
      const user = this.$store.state.user;
      if (!timezone && this.$store && this.$store.state.user) {
        timezone = this.$store.state.user.timezone;
      }
      if (!timezone) {
        timezone = "GMT";
      }
      return timezone;
    },

    createAPIStream(url, initialPage = 0, limit = 20) {
      function _stream(_url, _initialPage, _limit) {
        return new Promise(resolve => {
          callBffAPI({
            url: `${_url}${
              _url.includes("?") ? "&" : "?"
            }page=${_initialPage}&limit=${_limit}`,
            method: "GET",
            cache: true
          })
            .then(result => {
              _initialPage++;
              resolve({
                data: result.data,
                next() {
                  return _stream(_url, _initialPage, _limit);
                }
              });
            })
            .catch(error => {
              resolve({
                data: [],
                error: error
              });
            });
        });
      }
      return () => _stream(url, initialPage, limit);
    },

    massDataLoader(storeHere, url, initialPage = 0, limit = 20) {
      return new Promise(resolve => {
        const stream = this.createAPIStream(url, initialPage, limit);
        async function _take(_stream) {
          const { data, next } = await _stream();
          data.forEach(item => {
            storeHere.push(item);
          });
          if (data.length < limit) {
            resolve();
            return;
          }
          return _take(next);
        }
        return _take(stream);
      });
    },

    getLocaleDate(date) {
      if (!date) {
        return "";
      }
      return dayjs(date).format("YYYY-MM-DD hh:mm:ss");
    },

    async getUsers() {
      const result = await callBffAPI({
        url: `/identities?ownedBy=${this.$store.state.user.ownedBy}&idType=user&fields=identityId,name,_uri`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getCustomers() {
      const result = await callBffAPI({
        url: `/customers?sort=name:asc&fields=customerId,name,_uri`,
        method: "GET"
      });
      if (result == null) return null;
      return result.data;
    },

    async getCustomer(customerId) {
      const result = await callAPI({
        url: `${this.$store.state.idp_api_url}/customers/${customerId}`,
        method: "GET"
      });
      return result.data;
    },

    strTestCaseInsensitive(str, subStr) {
      const regex = new RegExp(subStr, "i");
      return regex.test(str);
    },

    loadExpanded() {
      if (!this.$store) {
        console.log("Missing store!");
        return;
      }
      const result = this.$store.state.appSettings[this.appSettingKey][
        "expanded"
      ];
      return result;
    },
    saveExpanded(value) {
      this.$store.commit("setAppSetting", {
        parent: this.appSettingKey,
        key: "expanded",
        value
      });
    },
    async calculatePropertiesCustomers(items) {
      // const customerUriList = this.distinct(items.map(p => p.mfCustomer._uri)).filter(p => p)
      // console.log(customerUriList)
      // // let customerNameHash = {}
      // // for (let url of customerUriList) {
      // //   const response = await callAPI({ url, method: "GET" });
      // //   if (response == null) return null;
      // // }
      // for (let item of items) {
      //   item.mfCustomer.name = item.mfCustomer._uri
      // }
    },
    async getDisplayNameForBreadCrumbs(type, id) {
      let url = null;
      switch (type) {
        case ":identityId":
          url = `/identities?identityId=${id}&fields=name`;
          break;
        case ":clientId":
          url = `/clients?client_id=${id}&fields=client_name`;
          break;
        case ":customerId":
          url = `/customers?customerId=${id}&fields=name`;
          break;
        case ":dataProviderId":
          url = `/data-providers?dataProviderId=${id}&fields=name`;
          break;
        case ":dataStoreId":
          url = `/data-stores?dataStoreId=${id}&fields=name`;
          break;
        case ":resourceServerId":
          url = `/resource-servers?resourceServerId=${id}&fields=resourceName`;
          break;
        case ":scopeId":
          url = `/scopes?scopeId=${id}&fields=scopeName`;
          break;
      }
      if (!url) return "";
      const method = "GET";
      const response = await callBffAPI({ url, method });
      if (!response.data?.length) return null;
      const data = response.data[0];
      switch (type) {
        case ":clientId":
          return data.client_name;
        case ":resourceServerId":
          return data.resourceName;
        case ":scopeId":
          return data.scopeName;
        default:
          return data.name;
      }
    },
    async setBreadCrumbs() {
      const mpath = this.$route.matched[0].path.split("/");
      const path = this.$route.path.split("/");
      // console.log(path, this.$route);
      let bread = [];
      for (let [i, p] of mpath.entries()) {
        if (!p) continue;
        if (p[0] === ":") {
          bread.push({
            text: await this.getDisplayNameForBreadCrumbs(p, path[i]),
            isDiplayName: true,
            disabled: true
          });
          continue;
        }
        bread.push({
          text: this.$t(p),
          isDiplayName: false,
          disabled: i !== 1,
          href: `/${p}`
        });
      }
      this.$store.commit("page", bread);
    }
  }
});
