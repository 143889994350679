const { callAPI } = require("ngt-frontend-core").apiOpsBff;
import {
  createTree,
  calculateNumOfChildrensAndSelected
} from "@/managers/tagsToTree";
import { treeForEach, isEqualObj } from "@/managers/utils";

const store = {
  namespaced: true,
  state: () => ({
    loadParams: null,
    items: [],
    errors: {}
  }),
  actions: {
    async load({ state }, params) {
      // console.log("[store] load");
      state.loadParams = params;

      if (
        state.items.length > 0 &&
        isEqualObj(state.loadParams, params) &&
        !params.force
      ) {
        return;
      }

      const { data: allTags } = await callAPI({
        url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/tag-collection/${params.collection}`,
        method: "GET"
      });

      const tagsTreeRoot = createTree(allTags);

      const { data: identityTags } = await callAPI({
        url: `/${params.entityName}/${params.entityId}/tags`,
        method: "GET"
      });

      treeForEach(
        tagsTreeRoot,
        node => (node.isSelected = identityTags.includes(node.id))
      );

      state.items = tagsTreeRoot.children;

      calculateNumOfChildrensAndSelected(state.items);

      // console.log(state.items);
    },
    async addNode({ state }, data) {},
    async deleteNode({ state }, id) {},
    async selectNode({ state }, id) {
      // console.log("add tag: ", id);
      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/${state.loadParams.entityName}/${state.loadParams.entityId}/tags`,
          method: "POST",
          data: { tag: id }
        });
        calculateNumOfChildrensAndSelected(state.items);
      } catch (err) {
        return err.message;
      }
    },
    async unselectNode({ state }, id) {
      // console.log("remove tag: ", id);
      try {
        await callAPI({
          url: `${process.env.VUE_APP_BFF_ORIGIN}/${state.loadParams.entityName}/${state.loadParams.entityId}/tags/${id}`,
          method: "DELETE"
        });
        calculateNumOfChildrensAndSelected(state.items);
      } catch (err) {
        return err.message;
      }
    },
    async cleanUp({ state }) {
      state.loadParams = null;
      state.items = [];
      state.errors = {};
    }
  }
};

export default store;
