const { callAPI } = require("ngt-frontend-core").apiOpsBff;

const store = {
  namespaced: true,
  state: () => ({
    loadParams: null,
    items: [],
    errors: {}
  }),
  actions: {
    async load({ state }, params) {
      // console.log("[store] load");
      state.loadParams = params;

      const { data: allAttrs } = await callAPI({
        url: `${process.env.VUE_APP_BFF_ORIGIN}/_abac/attr-collection/${params.collection}`,
        method: "GET"
      });

      const { data: storedAttrs } = await callAPI({
        url: `/${params.entityName}/${params.entityId}/attrs`,
        method: "GET"
      });

      const storedAttrsHash = {};
      storedAttrs.forEach(p => (storedAttrsHash[p["name"]] = p["value"]));
      // console.log(allAttrs, storedAttrs);

      state.items = allAttrs.map(p => ({
        name: p,
        value:
          typeof storedAttrsHash[p] !== "undefined" ? storedAttrsHash[p] : null
      }));
    },
    async save({ state, dispatch }, params) {
      const { data: storedAttrs } = await callAPI({
        url: `/${params.entityName}/${params.entityId}/attrs`,
        method: "GET"
      });
      // console.log("storedAttrs: ", storedAttrs);
      for (let attr of state.items) {
        const storedAttr = storedAttrs.find(p => p.name === attr.name);
        // Add
        if (!storedAttr) {
          await dispatch("addOrModify", { ...params, attr });
        }
        // Modify
        else if (attr.value && storedAttr.value !== attr.value) {
          await dispatch("addOrModify", { ...params, attr });
        }
        // Delete
        else if (
          (attr.value === "" || attr.value === null) &&
          storedAttr.value !== attr.value
        ) {
          await dispatch("delete", { ...params, attr });
        }
      }
    },
    async addOrModify({ state }, params) {
      await callAPI({
        url: `/${params.entityName}/${params.entityId}/attrs/${params.attr.name}`,
        method: "PUT",
        data: { value: params.attr.value }
      });
    },
    async delete({ state }, params) {
      await callAPI({
        url: `/${params.entityName}/${params.entityId}/attrs/${params.attr.name}`,
        method: "DELETE"
      });
    },
    async cleanUp({ state }) {
      state.loadParams = null;
      state.items = [];
      state.errors = {};
    }
  }
};

export default store;
