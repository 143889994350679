import getLocale from "../services/getLocale";
import getTimezone from "../services/getTimezone";
import {
  formatDate,
  formatTime,
  formatDateTime,
  formatDateTimeFromEPOC
} from "../services/dateTime";

const ServiceProviderPlugin = {
  // eslint-disable-next-line no-unused-vars
  install(Vue, options) {
    Vue.prototype.$services = {
      getLocale,
      getTimezone,
      dateTime: {
        formatDate,
        formatTime,
        formatDateTime,
        formatDateTimeFromEPOC
      }
    };
  }
};

export default ServiceProviderPlugin;
