const getTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!timezone) {
    return null;
  }

  return timezone;
};

export default getTimezone;
